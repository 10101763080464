import Vue from 'vue'
import Vuex from 'vuex'
import Swal from 'sweetalert2'
import { auth, db } from '../firebase'
import { collection, addDoc } from "firebase/firestore";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    overlay: false,
    drawer: false,
  },
  mutations: {
    SET_OVERLAY: (state, val) => state.overlay = val,
    SET_DRAWER: (state, val) => state.drawer = val,
  },
  actions: {

    // delete account request
    async deleteAccount({ commit }, { name, mobile, email }) {
      try {
        commit('SET_OVERLAY', true)
        const docRef = collection(db, "deleteAccountRequest");
        await addDoc(docRef, {
          'name': name,
          'mobile': mobile,
          'email': email,
          'status': true,
          'createdAt': new Date().getTime(),
          'updatedAt': null,
          'platform': 'web',
        });
        Swal.fire('Success!', 'Your request has been recored. Your account deletion process will start soon. Thank You!', 'success');
        commit('SET_OVERLAY', false);
      } catch (error) {
        console.error(error);
        Swal.fire('Error!', 'Something went wrong!', 'error');
      }
    },

    // submit your query
    async submitQuery({ commit }, { name, mobile, message }) {
      try {
        var user = auth.currentUser;
        commit('SET_OVERLAY', true)
        const docRef = collection(db, "queries");
        await addDoc(docRef, {
          'id': user != null ? user.uid : null,
          'name': name,
          'mobile': mobile,
          'message': message,
          'status': true,
          'platform': 'web',
          'createdAt': new Date().getTime(),
          'updatedAt': new Date().getTime(),
        });
        commit('SET_OVERLAY', false)
        Swal.fire('Submitted', 'Your request has been submitted successfully!. We\'ll contact you soon.', 'success')
      } catch (error) {
        commit('SET_OVERLAY', false)
        Swal.fire('Error!', 'Something went wrong!', 'error');
      }
    },
  },
  getters: {},
})
