import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
    apiKey: "AIzaSyB5p5uNWkAkIrDmdi7_a3Hywe_MkJGcoEo",
    authDomain: "toeato0.firebaseapp.com",
    projectId: "toeato0",
    storageBucket: "toeato0.appspot.com",
    messagingSenderId: "577785414038",
    appId: "1:577785414038:web:4c3f5106ec663c66139db7",
    measurementId: "G-LL9YVWR7HJ"
};
// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getFirestore(firebase);
export { firebase, db, auth };