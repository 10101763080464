import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'Home - Toeato: Food Delivery',
      metaTags: [
        { name: 'description', content: 'Toeato home page description' },
        { property: 'og:description', content: 'Toeato home page description' }
      ]
    },
  },
  {
    path: '/delete_account', name: 'Delete Account',
    component: () => import('../views/DeleteAccount.vue'),
    meta: {
      title: 'Delete Account - Toeato: Food Delivery',
      metaTags: [
        { name: 'description', content: 'Toeato contact page description' },
        { property: 'og:description', content: 'Toeato contact page description' }
      ]
    }
  },
  {
    path: '/contact', name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: 'Contact - Toeato: Food Delivery',
      metaTags: [
        { name: 'description', content: 'Toeato contact page description' },
        { property: 'og:description', content: 'Toeato contact page description' }
      ]
    }
  },
  {
    path: '/careers', name: 'Careers',
    component: () => import('../views/Careers.vue'),
    meta: {
      title: 'Privacy & Policy - Toeato: Food Delivery',
      metaTags: [
        { name: 'description', content: 'Toeato careers page description' },
        { property: 'og:description', content: 'Toeato careers page description' }
      ]
    }
  },
  {
    path: '/privacy-policy', name: 'Privacy & Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy & Policy - Toeato: Food Delivery',
      metaTags: [
        { name: 'description', content: 'Toeato privacy policy page description' },
        { property: 'og:description', content: 'Toeato privacy policy page description' }
      ]
    }
  },
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
