import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from './firebase'

Vue.config.productionTip = false

Vue.mixin({
  data: () => ({
    // validations
    nameRules: [
      v => !!v || '',
      v => (v && v.length > 3) || '',
    ],
    mobileRules: [
      v => !!v || '',
      v => (v && !isNaN(v)) || '',
      v => (v && v.length == 10) || '',
    ],
    emailRules: [
      v => !!v || '',
      v => (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || '',
    ],
    passwordRules: [
      v => !!v || '',
      v => (v && v.length >= 6) || '',
    ],
    messageRules: [
      v => !!v || 'Please write your message...',
      // v => (v && v.length > 20) || 'Please write your message...',
    ],
    socials: [
      { icon: 'mdi-facebook', to: 'https://www.facebook.com/people/Toeato/100086690146612/?mibextid=ZbWKwL', color: '#3b5998' },
      { icon: 'mdi-twitter', to: 'https://www.twitter.com/To_Eato', color: '#00acee' },
      { icon: 'mdi-youtube', to: 'https://www.youtube.com/@toeato', color: '#FF0000' },
      { icon: 'mdi-instagram', to: 'https://www.instagram.com/to_eato', color: '#8a3ab9' }
    ],
  }),
})


let app = '';
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
});
