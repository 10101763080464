<template>
  <v-app-bar app color="white" elevate-on-scroll height="60">
    <v-img
      src="../assets/logo.png"
      lazy-src="../assets/logo.png"
      alt="Toeato Logo"
      max-height="50"
      max-width="50"
      style="border-radius: 6px"
      @click="goto"
    />
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    goto() {
      if (this.$route.path != "/home") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss">
header {
  .v-btn {
    color: #fe3c5b !important;
    border-radius: 0px !important;
    &--active {
      border-bottom: 2px solid !important;
    }
  }
}
@media (max-width: 600px) {
  .menus {
    display: none !important;
  }
}
</style>