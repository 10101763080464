<template>
  <v-app>
    <v-overlay :value="overlay" v-if="overlay === true" absolute z-index="999">
      <v-progress-circular indeterminate size="50" />
    </v-overlay>
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>


<script>

import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  computed: {
    overlay() {
      return this.$store.state.overlay;
    },
    token() {
      return this.$store.state.user;
    },
  },
};
</script>


<style lang="scss">
$border-color: rgba(
  $color: #000000,
  $alpha: 0.25,
);
::-webkit-scrollbar {
  width: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
.v-application--wrap {
  background-color: transparent !important;
}
.v-toolbar__content {
  max-width: 1250px;
  padding: 0 12px !important;
  margin: 0 auto;
}
.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
  margin-right: 0 !important;
  margin-left: 12px;
}
/* Container */
.container {
  max-width: 1250px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 12px !important;
}
p {
  margin-bottom: 0 !important;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.5px;
  color: #35485d;
}
/* Button */
.v-btn {
  font-size: 13px !important;
  font-weight: normal !important;
}
/* Inputs */
.v-input input,
.v-input textarea {
  font-size: 13px !important;
  letter-spacing: 0.75px !important;
  border: none !important;
}
.v-input .v-label {
  font-size: 13px !important;
  letter-spacing: 1px !important;
}
.v-input__icon .v-icon.v-icon,
.v-select-list .v-icon.v-icon {
  font-size: 16px !important;
}
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid #4caf50 !important;
  // background-color: #4caf4f1c !important;
}
.v-text-field--outlined.v-input--has-state fieldset {
  border: 1px solid #f36c27 !important;
  // background-color: #fe3c5c19 !important;
}

.v-input {
  .v-input__slot {
    padding: 0 12px !important;
    min-height: 36px !important;
    .v-input__prepend-inner {
      margin-top: 6px !important;
    }
  }
}

// image
img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}

// text overflow
.text__overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>