<template>
  <v-footer color="white" class="py-6 py-sm-8 py-md-10 py-lg-12 elevation-6" padless elevation="0" style="border-top: .5px solid rgba(0, 0, 0, .25) !important;">
    <v-container class="footer">
      <div class="company">
        <div class="d-flex align-center" style="grid-gap: 6px;">
          <!-- <v-img src="../../assets/elogo.png" lazy-src="../../assets/elogo.png" alt="Easy Prayogshala Logo" max-width="80"></v-img>  -->
          <div class="d-flex flex-column" style="line-height: 1; grid-gap: 4px; letter-spacing: .5px;">
            <div class="font-weight-bold primary--text logo__text ep__text" v-text="'TOEATO'" />
            <div class="para" v-text="'To Eat Order Us'" />
          </div>
        </div> 
         
        <div class="socials my-3">
          <span class="para font-weight-bold black--text">Follow Us : </span>  
          <v-btn icon small v-for="(social, s) in socials" :key="s" color="secondary" target="_blank" :href="social.to">
            <v-icon size="14">{{ social.icon }}</v-icon>
          </v-btn>  
        </div>
      </div>
      <div class="footer__links">
        <div class="links">
          <div class="link__title">Company</div>
          <div class="d-flex flex-column">
            <router-link class="para" v-for="(inf, i) in info" :key="i" :to="inf.route">{{ inf.title }}</router-link>
          </div>
          
        </div>
        <div class="links">
          <div class="link__title">Contact</div>
          <div class="para" v-for="(con, c) in contact" :key="c">{{ con }}</div>
        </div>  
      </div>
    </v-container>  
  </v-footer>
</template>

<script>
export default {
  name: 'index-footer',
  data: () => ({
    info: [
      { title: 'Home', route: '/' },
      { title: 'Contact', route: '/contact' },
      { title: 'Privacy & Policy', route: '/privacy-policy' }
    ],
    contact: ['Birgunj-10, Nepal', 'toeato1@gmail.com', '+977 9814245916'],
  }),
}
</script>

<style scoped lang="scss">
.footer {
  display: grid;
  grid-template-columns: 280px calc(100% - 330px);
  grid-gap: 50px;  
  .company {
    .logo__text { font-size: 20px; }
  }
  .footer__links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));  
    .links {
      .link__title {
        font-weight: bold;
        margin-bottom: 16px;  
        letter-spacing: .5px;
        font-size: 17px;
      }
      .para { 
        margin-bottom: 4px; 
        &:last-child { margin-bottom: 0 !important; }
      }
    }
  }
  .para {
    color: #00063ab7;
    font-size: 13px; 
    font-weight: 400 !important;
    line-height: 1.3;
    text-decoration: none;
  }
  @media (max-width: 960px) {
    grid-gap: 40px;  
    .footer__links { grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)); }  
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)); grid-gap: 30px; 
    .footer__links { row-gap: 20px; }
  }
}
</style>